import React from 'react';

import { formatPrice, prettyTime, Image, isPreOrderCurrentlyPossible, calculateIsOpen } from '@bestelleck/shared';
import { convertDateToTime, DeliveryPlaceSimple, getOpeningHoursForDate, Restaurant } from '@bestelleck/utils';
import { DriveEta, Euro } from '@mui/icons-material';
import { Card, CardContent, Typography } from '@mui/material';
import { FiShoppingBag } from 'react-icons/fi';
import { IoLocationOutline, IoNavigateOutline, IoStar, IoTimerOutline } from 'react-icons/io5';
import LazyLoad from 'react-lazy-load';
import { shallowEqual, useSelector } from 'react-redux';

import PaymentOptionsComponent from '../../../../components/PaymentOptions/PaymentOptions';
import { RootState } from '../../../../redux/store';
import { Tag } from '../../../../types/Restaurant';
import { baseUrl, companyName } from '../../../../util/constants';
import { formatNumber } from '../../../../util/utils';

import CouponLabelComponent from './CouponLabel/CouponLabel';
import styles from './RestaurantCard.module.scss';

interface RestaurantCardProps {
    restaurant: Restaurant & { distance: number };
    isDelivery: boolean;
    place?: DeliveryPlaceSimple;
    restaurantTags: Tag[];
}

const RestaurantCard: React.FC<RestaurantCardProps> = ({ restaurant, place, isDelivery, restaurantTags }) => {
    const logo = `${baseUrl}/image/${restaurant.avatarImageLink}?height=100`;
    const image = `${baseUrl}/image/${restaurant.imageLink}?height=480`;

    const orderType = useSelector((state: RootState) => state.orderType.orderType, shallowEqual);

    const delivery = place;
    const tags = restaurant.tags
        .map((tag) => restaurantTags.find((tagInfo) => tag === tagInfo.id)?.name)
        .filter((tag) => tag)
        .join(', ');

    let label;

    if (isDelivery && restaurant.menu.coupon?.delivery) {
        label = <CouponLabelComponent coupon={restaurant.menu.coupon.delivery} isDelivery={isDelivery} />;
    } else if (!isDelivery && restaurant.menu.coupon?.pickup) {
        label = <CouponLabelComponent coupon={restaurant.menu.coupon.pickup} isDelivery={isDelivery} />;
    } else if (isDelivery && restaurant.menu.coupon?.pickup) {
        label = <CouponLabelComponent coupon={restaurant.menu.coupon.pickup} isDelivery={false} />;
    }

    if (isDelivery && restaurant.delivery?.label !== '') {
        label = restaurant.delivery?.label;
    } else if (!isDelivery && restaurant.pickup?.label !== '') {
        label = restaurant.pickup?.label;
    }
    const currentDate = new Date();
    const currentTime = convertDateToTime(currentDate);
    const isOpen = calculateIsOpen(restaurant, orderType);
    const openingSchedule = isDelivery ? restaurant.delivery : restaurant.pickup;
    const nextOpeningFrame = getOpeningHoursForDate(
        openingSchedule?.days,
        openingSchedule?.exceptions,
        currentDate,
    )?.find((frame) => frame.from > currentTime);
    const preOrderPossible = isPreOrderCurrentlyPossible(restaurant, isDelivery, isOpen);
    return (
        <Card className={styles.root} id={restaurant.id}>
            <LazyLoad className={styles.imageContainer} offset={300}>
                <Image className={isOpen || preOrderPossible ? styles.image : styles.imageClosed} src={image} />
            </LazyLoad>

            <div className={styles.leftLabels}>
                {!isOpen && !nextOpeningFrame && <div className={styles.closedLabel}>Geschlossen</div>}
                {!isOpen && nextOpeningFrame && (
                    <div className={styles.closedLabel}>Öffnet um {prettyTime(nextOpeningFrame.from)} Uhr</div>
                )}
                {preOrderPossible && <div className={styles.preOrderLabel}>Vorbestellung möglich</div>}
            </div>

            <div className={styles.labels}>
                {label && <div className={styles.couponLabel}>{label}</div>}
                {restaurant.onlyOnBestellEck && <div className={styles.onlyLabel}>Nur bei {companyName}!</div>}
            </div>

            <div className={styles.details}>
                <div className={styles.logoContainer}>
                    <LazyLoad className={styles.logoContainer} offset={300}>
                        <Image className={styles.logo} src={logo} isRound={true} />
                    </LazyLoad>
                </div>

                <CardContent className={styles.content}>
                    <div className={styles.title}>
                        <Typography component="h5" variant="h5">
                            {restaurant.name}
                        </Typography>
                    </div>

                    <div className={styles.subtitle}>
                        <Typography variant="subtitle1">{tags}</Typography>
                        <div className={styles.rating}>
                            <IoStar />
                            <span>
                                {restaurant.ratings.average !== 0 ? formatNumber(restaurant.ratings.average) : '-'}/ 5
                            </span>
                            <span className={styles.count}>({restaurant.ratings.count})</span>
                        </div>
                    </div>

                    {isDelivery && delivery && (
                        <div className={styles.footer}>
                            <div className={styles.footerEntry}>
                                <IoTimerOutline fontSize="small"></IoTimerOutline>
                                {restaurant.orderTime.min} - {restaurant.orderTime.max} min
                            </div>
                            <div className={styles.footerEntry}>
                                <DriveEta fontSize="small"></DriveEta>
                                {formatPrice(delivery.fee)}
                            </div>
                            <div className={styles.footerEntry}>
                                <Euro fontSize="small"></Euro>
                                Min: {formatPrice(delivery.minimumOrderValue)}{' '}
                            </div>
                        </div>
                    )}
                    {!isDelivery && (
                        <div className={styles.footer}>
                            <div className={styles.footerEntry}>
                                <FiShoppingBag fontSize="small"></FiShoppingBag>
                                {restaurant.orderTime.min} - {restaurant.orderTime.max} min
                            </div>
                            {restaurant.distance !== -1 && (
                                <div className={styles.footerEntry}>
                                    <IoNavigateOutline fontSize="small"></IoNavigateOutline>
                                    {restaurant.distance.toFixed(2)} km
                                </div>
                            )}
                            <div className={styles.footerEntry}>
                                <IoLocationOutline fontSize="small"></IoLocationOutline>
                                {restaurant.contact.city}
                            </div>
                        </div>
                    )}
                    <div className={styles.paymentOptions}>
                        <PaymentOptionsComponent
                            paymentOptions={restaurant.payment.paymentOptions}
                        ></PaymentOptionsComponent>
                    </div>
                </CardContent>
            </div>
        </Card>
    );
};

export default React.memo(RestaurantCard);
