import { OrderType, Restaurant } from '@bestelleck/utils';
import { Dispatch } from 'redux';

import { baseUrl } from '../../util/constants';
import { customFetch } from '../../util/utils';

import { getRestaurantsError, getRestaurantsPending, getRestaurantsSuccess } from './restaurant.actions';
import { RestaurantAction } from './restaurant.type';

export const fetchRestaurants = (
    lat?: string,
    lon?: string,
    orderType?: string,
): ((dispatch: Dispatch<RestaurantAction>) => void) => {
    let url = `${baseUrl}/restaurants?useNewScheduleDays=true`;
    if (lat !== '') {
        url = `${url}&latitude=${lat}`;
    }
    if (lon !== '') {
        url = `${url}&longitude=${lon}`;
    }
    if (orderType) {
        url = `${url}&orderType=${orderType}`;
    }

    if (orderType === OrderType.Delivery && (lat === '' || lon === '')) {
        return (dispatch: Dispatch<RestaurantAction>) => {
            dispatch(getRestaurantsSuccess([]));
        };
    }

    return async (dispatch: Dispatch<RestaurantAction>) => {
        dispatch(getRestaurantsPending());
        try {
            const response = await customFetch<Restaurant[]>(url);
            dispatch(getRestaurantsSuccess(response));
            return response;
        } catch (error: any) {
            dispatch(getRestaurantsError(error));
        }
    };
};
