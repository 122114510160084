import {
    convertDateToTime,
    getOpeningHoursForDate,
    isRestaurantOpenOnDate,
    OrderType,
    Restaurant,
} from '@bestelleck/utils';
import moment from 'moment';

import { getLocalDate } from './date';

export const calculateIsOpen = (restaurant: Restaurant, orderType: OrderType): boolean => {
    const hasDelivery = restaurant.delivery ? true : false;
    const date = getLocalDate();
    if (orderType === OrderType.Delivery && hasDelivery) {
        return isRestaurantOpenOnDate(restaurant.delivery?.days, restaurant.delivery?.exceptions, date);
    } else if (orderType === OrderType.PickUp) {
        return isRestaurantOpenOnDate(restaurant.pickup?.days, restaurant.pickup?.exceptions, date);
    }
    return true;
};

export const isPreOrderCurrentlyPossible = (
    restaurant: Restaurant | undefined,
    isDelivery: boolean,
    isOpen: boolean,
): boolean => {
    if (!restaurant) return false;
    const preOrderPossible =
        isDelivery && restaurant.delivery ? restaurant.delivery.supportsPreOrder : restaurant.pickup?.supportsPreOrder;
    const openingSchedule = isDelivery && restaurant.delivery ? restaurant.delivery : restaurant.pickup;
    const currentDate = new Date();

    const currentTime = convertDateToTime(currentDate);
    const nextOpeningFrame = getOpeningHoursForDate(
        openingSchedule?.days,
        openingSchedule?.exceptions,
        currentDate,
    )?.find((frame) => frame.from > currentTime);
    const nextOpeningFrameExistsToday = nextOpeningFrame ? true : false;
    return Boolean(!isOpen && nextOpeningFrameExistsToday && isAfter9am && preOrderPossible);
};

export const isAfter9am = moment().isAfter(moment('09:00:00', 'HH:mm:ss'));
