import { PaymentOption, RestaurantDetail, Order } from '@bestelleck/utils';
import { AlertColor } from '@mui/material';
import { createAction } from '@reduxjs/toolkit';

import { Contact, FilterAttributes, PaymentOptions } from './app.types';

export const setSelectedRestaurant = createAction<{ restaurant: RestaurantDetail }>('SET_SELECTED_RESTAURANT');

export const setCurrentRoute = createAction<{ route: string }>('SET_ROUTE');

export const addTag = createAction<{ tag: string }>('ADD_TAG');

export const removeTag = createAction<{ tag: string }>('REMOVE_TAG');

export const setSearch = createAction<{ query: string }>('SET_SEARCH');

export const setFilter = createAction<{ attribute: FilterAttributes; value: string | number }>('SET_FILTER');

export const setOrder = createAction<{ order: Order }>('SET_ORDER');

export const setContact = createAction<{ contact: Contact }>('SET_CONTACT');

export const setRated = createAction('SET_RATED');

export const setPaymentMethods = createAction<{ options: PaymentOptions }>('SET_PAYMENT_METHODS');

export const setSelectedPaymentMethod = createAction<{ option: PaymentOption }>('SET_SELECTED_PAYMENT_METHOD');

export const showMessage = createAction<{ message: string; severity: AlertColor }>('SHOW_MESSAGE');

export const hideMessage = createAction('HIDE_MESSAGE');
