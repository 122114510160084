export function dayOfWeekAsString(dayIndex: number): string {
    return ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][dayIndex] || '';
}

export const prettyTime = (time: number): string => {
    let hours = Math.floor(time / 60);
    const minutes = time % 60;
    if (hours > 23) {
        hours = hours - 24;
    }
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

export const getLocalDate = (): Date => {
    return new Date(new Date().toLocaleString('en-EN', { timeZone: 'Europe/Berlin' }));
};
