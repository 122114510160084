import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';

import { hideMessage } from '../../redux/app/app.actions';
import { RootState } from '../../redux/store';
import { Alert } from '../Alert/Alert';

export const CustomizedSnackbar: React.FC = () => {
    const dispatch = useDispatch();

    const { message, open, severity } = useSelector((state: RootState) => state.app.status);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(hideMessage());
    };

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            sx={{ width: '80%' }}
            onClick={() => {
                dispatch(hideMessage());
            }}
        >
            <div>
                {open && (
                    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                )}
            </div>
        </Snackbar>
    );
};
