import { combineReducers } from '@reduxjs/toolkit';
import { captureException } from '@sentry/react';
import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';

import appReducer from './app/app.reducer';
import { AppState, AppStatus } from './app/app.types';
import cartReducer from './cart/reducer';
import { CartAction, CartState, CartDispatchType } from './cart/types';
import orderTypeReducer from './orderType/orderType.reducer';
import { OrderTypeState } from './orderType/types';
import restaurantReducer from './restaurant/restaurant.reducer';
import { RestaurantAction, RestaurantDispatchType, RestaurantState } from './restaurant/restaurant.type';

export type RootState = {
    cart: CartState;
    app: AppState;
    restaurant: RestaurantState;
    orderType: OrderTypeState;
};
type DispatchType = CartDispatchType | RestaurantDispatchType;

type ActionType = CartAction | RestaurantAction;

const STORAGE_VERSION = 10;
const CART_VERSION = 4;

export const saveToLocalStorage: (state: RootState) => void = (state: RootState) => {
    try {
        const stateCopy: RootState = {
            ...state,
            app: {
                ...state.app,
                search: '',
                filters: {
                    payment: 'none',
                    deliveryCost: -1,
                    minimumOrderValue: -1,
                    rating: 'none',
                },
                version: STORAGE_VERSION,
                cartVersion: CART_VERSION,
            },
        };
        const serialisedState = JSON.stringify(stateCopy);
        localStorage.setItem('persistantState', serialisedState);
    } catch (e) {
        captureException(e);
    }
};

const loadFromLocalStorage = () => {
    try {
        const serialisedState = localStorage.getItem('persistantState');
        if (serialisedState === null) return undefined;
        const parsed: RootState = JSON.parse(serialisedState);
        if (parsed.app.version !== STORAGE_VERSION) return undefined;

        const status: AppStatus = {
            message: '',
            open: false,
            severity: 'info',
        };

        const stateCopy: RootState = {
            ...parsed,
            app: {
                ...parsed.app,
                search: '',
                filters: {
                    payment: 'none',
                    deliveryCost: -1,
                    minimumOrderValue: -1,
                    rating: 'none',
                },
                status: status,
            },
        };
        if (parsed.app.cartVersion !== CART_VERSION) {
            stateCopy.cart.carts = [];
        }
        return stateCopy;
    } catch (e) {
        captureException(e);
        return undefined;
    }
};

const rootReducer = combineReducers({
    cart: cartReducer,
    app: appReducer,
    restaurant: restaurantReducer,
    orderType: orderTypeReducer,
});

export const store: Store<RootState, ActionType> & {
    dispatch: DispatchType;
} = createStore(rootReducer, loadFromLocalStorage(), applyMiddleware(thunk));

store.subscribe(() => saveToLocalStorage(store.getState()));
