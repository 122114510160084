import { GeoLocation, Location, Place } from '../types/Geo';
import { baseUrlV2, geoEndpoint } from '../util/constants';
import { customFetch } from '../util/utils';

export const reverseGeoCode = async ({ latitude, longitude }: GeoLocation): Promise<Location[]> => {
    if (latitude === '') {
        return [];
    }

    const url = `${baseUrlV2}/geo/location?latitude=${latitude}&longitude=${longitude}`;
    return customFetch(url);
};

export const reverseLatLong = async ({ latitude, longitude }: GeoLocation): Promise<Place> => {
    const url = `${geoEndpoint}/reverse?lat=${latitude}&lon=${longitude}&format=json&addressdetails=1`;
    return customFetch<Place>(url, {
        headers: { 'accept-language': 'de' },
    });
};
