import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';

import { handleErrorsJson, ImgWithFallback } from '@bestelleck/shared';
import { filterGeoResults, removeDuplicatesByName, Place, getPlaceDisplay } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
    EmojiTransportation,
    Fastfood,
    InsertEmoticon,
    LocationOn,
    More,
    Payment,
    PhoneDisabled,
} from '@mui/icons-material';
import { Button, Card, CardContent, Typography, Autocomplete, TextField, debounce } from '@mui/material';
import { captureException } from '@sentry/react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { showMessage } from '../../redux/app/app.actions';
import { setPlace } from '../../redux/orderType/orderType.actions';
import { RootState } from '../../redux/store';
import { reverseGeoCode, reverseLatLong } from '../../services/geo.service';
import { companyName, geoEndpoint, isHunger } from '../../util/constants';
import { useTrackView } from '../../util/tracking/trackPage';

import styles from './Entry.module.scss';

declare const Modernizr: any;

const Entry: React.FC = () => {
    const history = useHistory();
    const { trackEvent } = useMatomo();
    const dispatch: Dispatch<any> = useDispatch();
    useTrackView('Home');

    const [location, setLocation] = useState<Place>();

    let backgroundClasses = [styles.background];
    if (Modernizr.webp) {
        backgroundClasses = [styles.backgroundWebp, ...backgroundClasses];
    }

    if (isHunger && !Modernizr.webp) {
        backgroundClasses = [styles.backgroundHunger, ...backgroundClasses];
    }

    if (isHunger && Modernizr.webp) {
        backgroundClasses = [styles.backgroundHungerWebp, ...backgroundClasses];
    }

    const { place } = useSelector((state: RootState) => state.orderType, shallowEqual);

    const [isLoading, setIsLoading] = useState(false);
    const [geoResults, setGeoResults] = useState<Place[]>([]);
    const [detailedSearch, setDetailedSearch] = useState('');
    const [geoSearch, setGeoSearch] = useState('');
    const [isClicked, setClicked] = useState(false);
    const [locationLoading, setLocationLoading] = useState(false);

    const onInputChange = useCallback(
        (newInputValue: string) => {
            setGeoSearch(newInputValue);
            trackEvent({
                action: 'Search address',
                category: 'Home',
                name: newInputValue,
            });
        },
        [trackEvent],
    );

    useEffect(() => {
        function handleLocationClick() {
            if (navigator.geolocation) {
                setLocationLoading(true);
                navigator.geolocation.getCurrentPosition(success, (error) => {
                    setLocationLoading(false);
                    // code 1 = PERMISSION_DENIED
                    if (error.code !== 1) {
                        dispatch(
                            showMessage({
                                message: 'Dein aktueller Standort konnte nicht bestimmt werden.',
                                severity: 'error',
                            }),
                        );
                    }
                });
            }
        }
        async function success(position: GeolocationPosition) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            try {
                const location = await reverseLatLong({ latitude: `${latitude}`, longitude: `${longitude}` });
                setLocation({
                    ...location,
                    customDisplayName: getPlaceDisplay(location),
                    isCurrentLocation: true,
                });
                if (place.osm_id === 0) {
                    dispatch(
                        setPlace({
                            place: {
                                ...location,
                                customDisplayName: getPlaceDisplay(location),
                                isCurrentLocation: false,
                            },
                        }),
                    );
                }
            } catch (error: any) {
                captureException(error);
            } finally {
                setLocationLoading(false);
            }
        }

        if (isClicked) {
            handleLocationClick();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isClicked]);

    const debouncedChangeHandler = useMemo(() => debounce(onInputChange, 500), [onInputChange]);

    useEffect(() => {
        if (geoSearch !== '' && geoSearch.length > 2) {
            setGeoResults([]);
            setIsLoading(true);
            fetch(`${geoEndpoint}/search?q=${geoSearch}&limit=20&format=json&addressdetails=1`, {
                headers: { 'accept-language': 'de' },
            })
                .then(handleErrorsJson)
                .then((response) => response.json())
                .then((response: Place[]) => {
                    const filtered = filterGeoResults(response);
                    setGeoResults(removeDuplicatesByName(filtered));
                    setIsLoading(false);
                })
                .catch((error) => {
                    captureException(error);
                    setIsLoading(false);
                    dispatch(
                        showMessage({
                            message: 'Es ist ein Fehler aufgetreten, bitte versuche es erneut.',
                            severity: 'error',
                        }),
                    );
                });
        }
    }, [geoSearch, dispatch]);

    const updateLocation = async (locationUpdate: Place) => {
        try {
            const geo = await reverseGeoCode({ latitude: locationUpdate.lat, longitude: locationUpdate.lon });
            if (geo.length === 0) {
                return false;
            }
            dispatch(
                setPlace({
                    place: { ...locationUpdate, isCurrentLocation: false },
                }),
            );
            trackEvent({
                action: 'Select address',
                category: 'Home',
                name: locationUpdate.customDisplayName,
            });
            return true;
        } catch (error) {
            captureException(error);
        }
    };

    const validateLocation = async (locationUpdate?: Place | string): Promise<boolean> => {
        if (locationUpdate && typeof locationUpdate !== 'string') {
            const found = await updateLocation(locationUpdate);
            if (!found) return false;
            return true;
        }
        return false;
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        search();
    };

    const search = async (locationUpdate?: Place | string) => {
        if (detailedSearch === '' && !locationUpdate) {
            history.push('/discover');
        } else if (locationUpdate) {
            const valid = await validateLocation(locationUpdate);
            if (valid) {
                history.push('/discover');
            } else {
                dispatch(
                    showMessage({
                        message: 'Bitte gebe eine valide Adresse ein.',
                        severity: 'error',
                    }),
                );
            }
        } else {
            const valid = await validateLocation(place);
            if (valid) {
                history.push('/discover');
            } else {
                dispatch(
                    showMessage({
                        message: 'Bitte gebe eine valide Adresse ein.',
                        severity: 'error',
                    }),
                );
            }
        }
    };

    let linkApple = 'https://apps.apple.com/de/app/bestelleck/id1577427878';
    let linkAndroid = 'https://play.google.com/store/apps/details?id=com.slavisamarkovic.BestellEck';

    if (isHunger) {
        linkAndroid = 'https://play.google.com/store/apps/details?id=com.hungerhunger.hungerhunger&hl=de';
        linkApple = 'https://apps.apple.com/de/app/hunger-hunger/id6736884734';
    }

    let options = location ? [location, ...geoResults] : geoResults;

    if (detailedSearch !== '' && geoResults.length === 0 && location) {
        options = [location, { notFound: true } as any];
    }

    if (locationLoading) {
        options = [{ notFound: true, isCurrentLocation: true } as any, ...geoResults];
    }

    return (
        <div className={styles.Entry}>
            <div className={backgroundClasses.join(' ')}>
                <div className={styles.container}>
                    <h3 id="title">
                        {!isHunger && (
                            <>
                                <p>Hunger?</p> Bestell bei dir ums <span>Eck</span>!
                            </>
                        )}
                        {isHunger && (
                            <>
                                <p>hast du hunger?</p> einfach essen bestellen!
                            </>
                        )}
                    </h3>
                    <h4>Jetzt Entdecken:</h4>
                    <div className={styles.searchContainer}>
                        <form
                            className={styles.locationForm}
                            onSubmit={onSubmit}
                            onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                            }}
                        >
                            <div className={styles.search}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    freeSolo
                                    color="secondary"
                                    loading={isLoading}
                                    onClick={() => setClicked(true)}
                                    onFocus={() => setClicked(true)}
                                    autoHighlight
                                    groupBy={(option) =>
                                        option.isCurrentLocation ? 'Aktueller Standort' : 'Vorschläge'
                                    }
                                    loadingText={'Wird geladen...'}
                                    isOptionEqualToValue={(option, value) => {
                                        return option.customDisplayName === value.customDisplayName;
                                    }}
                                    renderGroup={(params) => {
                                        return (
                                            <div key={params.key}>
                                                <div className={styles.groupName}>{params.group}</div>
                                                <div>{params.children}</div>
                                            </div>
                                        );
                                    }}
                                    renderOption={(props, option) => {
                                        // eslint-disable-next-line react/prop-types
                                        const className = props.className
                                            ? // eslint-disable-next-line react/prop-types
                                              [props.className, styles.listItem].join(' ')
                                            : styles.listItem;

                                        // eslint-disable-next-line react/prop-types
                                        const notFoundClass = props.className
                                            ? // eslint-disable-next-line react/prop-types
                                              [props.className, styles.notFound].join(' ')
                                            : styles.notFound;
                                        if (option.notFound && !locationLoading) {
                                            return (
                                                <>
                                                    <li key={`notFound`} className={notFoundClass}>
                                                        Keine Vorschläge gefunden
                                                    </li>
                                                </>
                                            );
                                        }
                                        if (option.notFound && locationLoading) {
                                            return (
                                                <li key={`loading`} className={notFoundClass}>
                                                    Wird geladen...
                                                </li>
                                            );
                                        }
                                        return (
                                            <>
                                                <li
                                                    {...props}
                                                    key={`${option.place_id}-${option.isCurrentLocation}`}
                                                    className={className}
                                                >
                                                    {option.customDisplayName}
                                                </li>
                                            </>
                                        );
                                    }}
                                    getOptionLabel={(option) => (option as Place).customDisplayName || ''}
                                    filterOptions={(x) => x}
                                    onChange={(selected, value) => {
                                        value = value as Place;
                                        if (value !== null) {
                                            search(value);
                                        }
                                    }}
                                    defaultValue={place || null}
                                    value={place || null}
                                    options={options}
                                    className={styles.autoComplete}
                                    onInputChange={(event, newInputValue) => {
                                        setDetailedSearch(newInputValue);
                                        debouncedChangeHandler(newInputValue);
                                    }}
                                    handleHomeEndKeys
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                color="secondary"
                                                placeholder="Adresse, z.B. Hauptstraße 1"
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <Button
                                id="submitButton"
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className={styles.searchButton}
                            >
                                Los gehts!
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
            <div className={styles.instructionsContainer}>
                <h3>So funktioniert {companyName}:</h3>
                <div className={styles.instructions}>
                    <div className={styles.flexContainer}>
                        <Card className={styles.card}>
                            <CardContent>
                                <LocationOn style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Gib deine Postleitzahl an</Typography>
                                <Typography className={styles.text}>Finde alle Restaurants in der Umgebung</Typography>
                            </CardContent>
                        </Card>
                        <Card className={styles.card}>
                            <CardContent>
                                <EmojiTransportation
                                    style={{ fontSize: 40 }}
                                    className={styles.icon}
                                ></EmojiTransportation>
                                <Typography variant="h5">Abholung oder Lieferung</Typography>
                                <Typography className={styles.text}>
                                    Entscheide, ob du dein Essen liefern lassen möchtest oder es selbst beim Restaurant
                                    abholst.
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className={styles.flexContainer}>
                        <Card className={styles.card}>
                            <CardContent>
                                <Fastfood style={{ fontSize: 40 }} className={styles.icon}></Fastfood>
                                <Typography variant="h5">Bestellen</Typography>
                                <Typography className={styles.text}>
                                    Suche in der Speisekarte die gewünschten Gerichte, schicke deine Bestellung ab und
                                    bezahle bequem online.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={styles.card}>
                            <CardContent>
                                <InsertEmoticon style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Genießen!</Typography>
                                <Typography className={styles.text}>Lass dir dein Essen schmecken!</Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
            <div className={styles.app}>
                <div>
                    <div className={styles.appInfo}>
                        <h1>Die {companyName} App herunterladen</h1>
                        <h2>Jetzt Restaurants in deiner Nähe entdecken</h2>

                        <div>
                            <a href={linkApple} target="_blank" rel="noreferrer">
                                <img src="/assets/ios.svg" alt="App Store" />
                            </a>
                            <a href={linkAndroid} target="_blank" rel="noreferrer">
                                <ImgWithFallback
                                    src="/assets/android.webp"
                                    fallback="/assets/android.png"
                                    alt="Google Play"
                                />
                            </a>
                        </div>
                    </div>
                    {!isHunger && (
                        <ImgWithFallback
                            src="/assets/app.webp"
                            fallback="/assets/app.png"
                            alt=""
                            className={styles.phone}
                        />
                    )}
                    {isHunger && (
                        <ImgWithFallback
                            src="/assets/hungerApp.webp"
                            fallback="/assets/hungerApp.png"
                            alt=""
                            className={styles.phone}
                        />
                    )}
                </div>
            </div>
            <div className={styles.whyContainer}>
                <h3>Warum {companyName}:</h3>
                <div className={styles.instructions}>
                    <div className={styles.flexContainer}>
                        <Card className={styles.card}>
                            <CardContent>
                                <LocationOn style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5" className={styles.header}>
                                    Lokal
                                </Typography>
                                <Typography className={styles.text}>
                                    Mit einer Bestellung unterstützt du die lokalen Restaurants. Gerade in der aktuellen
                                    Zeit sind die Restaurants auf jeden Cent angewiesen.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={styles.card}>
                            <CardContent>
                                <PhoneDisabled style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Kein Telefonchaos</Typography>
                                <Typography>
                                    Verbindung abgebrochen, Leitung belegt, Bestellung falsch verstanden oder etwas
                                    vergessen? Kein Problem, mit {companyName} wird die Bestellung zum Komfort.
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <div className={styles.flexContainer}>
                        <Card className={styles.card}>
                            <CardContent>
                                <More style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Mehr Auswahl</Typography>
                                <Typography>
                                    Wir geben allen Restaurants in deiner Umgebung die Möglichkeit, Bestellungen online
                                    entgegenzunehmen. Dadurch ermöglichen wir dir überall zu bestellen, auch wenn es nur
                                    zum Abholen ist.
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={styles.card}>
                            <CardContent>
                                <Payment style={{ fontSize: 40 }} className={styles.icon} />
                                <Typography variant="h5">Kontaktlos bezahlen</Typography>
                                <Typography>
                                    Gerade kein Bargeld dabei? Dein Lieblingsrestaurant nimmt keine Karte? Kein Problem,
                                    da du bei {companyName} online bezahlen kannst.
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Entry;
