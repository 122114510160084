import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { handleErrorsJson } from '@bestelleck/shared';
import { RestaurantDetail, filterGeoResults, getPlaceDisplay, removeDuplicatesByName, Place } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
    AutocompleteGroupedOption,
    CircularProgress,
    debounce,
    InputAdornment,
    TextField,
    useAutocomplete,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { MdOutlinePlace } from 'react-icons/md';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { showMessage } from '../../redux/app/app.actions';
import { RootState } from '../../redux/store';
import { reverseLatLong } from '../../services/geo.service';
import { getRestaurantPlace } from '../../services/restaurant.service';
import { geoEndpoint } from '../../util/constants';

import styles from './Address.module.scss';

type AddressComponentProps = {
    onSelect?: (place: Place) => void;
    restaurant?: RestaurantDetail;
    showHelp: boolean;
    increaseTries?: () => void;
};

export const AddressComponent: React.FC<AddressComponentProps> = ({
    onSelect,
    restaurant,
    increaseTries,
    showHelp,
}) => {
    const { trackEvent } = useMatomo();
    const dispatch = useDispatch();

    const { place } = useSelector((state: RootState) => state.orderType, shallowEqual);

    const [error, setError] = useState('');
    const [location, setLocation] = useState<Place>();
    const [isLoading, setIsLoading] = useState(false);
    const [locationLoading, setLocationLoading] = useState(false);
    const [geoResults, setGeoResults] = useState<Place[]>([]);
    const [geoSearch, setGeoSearch] = useState('');

    const onInputChange = useCallback(
        (_event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
            trackEvent({
                action: 'Search address',
                category: 'Home',
                name: newInputValue,
            });
            setGeoSearch(newInputValue);
        },
        [trackEvent],
    );
    const debouncedChangeHandler = useMemo(() => debounce(onInputChange, 500), [onInputChange]);

    useEffect(() => {
        return () => {
            debouncedChangeHandler.clear();
        };
    }, [debouncedChangeHandler]);

    useEffect(() => {
        function handleLocationClick() {
            if (navigator.geolocation) {
                setLocationLoading(true);
                navigator.geolocation.getCurrentPosition(success, (error) => {
                    setLocationLoading(false);
                    if (error.code !== 1) {
                        dispatch(
                            showMessage({
                                message: 'Dein aktueller Standort konnte nicht bestimmt werden.',
                                severity: 'error',
                            }),
                        );
                    }
                });
            }
        }
        async function success(position: GeolocationPosition) {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            try {
                const location = await reverseLatLong({ latitude: `${latitude}`, longitude: `${longitude}` });
                setLocation({
                    ...location,
                    customDisplayName: getPlaceDisplay(location),
                    isCurrentLocation: true,
                });
            } catch (error: any) {
                dispatch(
                    showMessage({
                        message: 'Dein aktueller Standort konnte nicht bestimmt werden.',
                        severity: 'error',
                    }),
                );
            } finally {
                setLocationLoading(false);
            }
        }

        handleLocationClick();
    }, [dispatch]);

    useEffect(() => {
        if (place.lat !== '' && place.lon !== '') {
            setGeoResults([place]);
        }
    }, [place]);

    useEffect(() => {
        if (geoSearch !== '' && geoSearch.length > 2) {
            setIsLoading(true);

            fetch(`${geoEndpoint}/search?q=${geoSearch}&limit=20&format=json&addressdetails=1`, {
                headers: { 'accept-language': 'de' },
            })
                .then(handleErrorsJson)
                .then((response) => response.json())
                .then((response: Place[]) => {
                    const filtered = filterGeoResults(response);
                    if (filtered.length === 0 && increaseTries) {
                        increaseTries();
                    }
                    setGeoResults(removeDuplicatesByName(filtered));
                    setIsLoading(false);
                })
                .catch((error) => {
                    captureException(error);
                    setError('Es ist ein Fehler aufgetreten, bitte versuche es erneut');
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoSearch]);

    let options = location ? [location, ...geoResults] : geoResults;

    if (geoSearch !== '' && geoResults.length === 0 && location) {
        options = [location, { notFound: true } as any];
    }

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
        id: 'adressSearch',
        options: options,
        isOptionEqualToValue: (option, value) => {
            return option.customDisplayName === value.customDisplayName;
        },
        freeSolo: true,
        groupBy: (option) => (option.isCurrentLocation ? 'Aktueller Standort' : 'Vorschläge'),
        getOptionLabel: (option) => getPlaceDisplay(option as Place),
        filterOptions: (x) => x,
        onChange: async (selected, value) => {
            if (value === null || typeof value === 'string' || value instanceof String) return;

            if (restaurant) {
                try {
                    const foundDeliveryPace = await getRestaurantPlace(restaurant?.id, value.lat, value.lon);
                    if (onSelect && foundDeliveryPace) {
                        onSelect({ ...value, isCurrentLocation: false });
                    } else {
                        trackEvent({
                            action: 'Address Not in delivery area',
                            category: 'Home',
                            name: `${value.customDisplayName} - ${restaurant.name}`,
                        });
                        setError(
                            `${value.customDisplayName} liegt leider nicht im Liefergebiet von ${restaurant.name}`,
                        );
                    }
                } catch (error) {
                    trackEvent({
                        action: 'Address Not in delivery area',
                        category: 'Home',
                        name: `${value.customDisplayName} - ${restaurant.name}`,
                    });
                    setError(`${value.customDisplayName} liegt leider nicht im Liefergebiet von ${restaurant.name}`);
                }
            } else {
                if (onSelect) {
                    onSelect({ ...value, isCurrentLocation: false });
                }
            }
        },
        onInputChange: debouncedChangeHandler,
        handleHomeEndKeys: true,
        open: true,
    });

    const InputProps = getInputProps();

    return (
        <div className={styles.input} {...getRootProps()}>
            <>
                <TextField
                    placeholder="Adresse, z.B. Hauptstraße 1"
                    className={styles.input}
                    size="medium"
                    autoFocus
                    color="secondary"
                    error={error !== ''}
                    helperText={error !== '' ? error : ''}
                    InputProps={{
                        inputProps: {
                            ...InputProps,
                            onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                    e.stopPropagation();
                                }
                            },
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {isLoading && <CircularProgress style={{ width: 20, height: 20 }} color="inherit" />}
                            </InputAdornment>
                        ),
                    }}
                />

                <ul {...getListboxProps()} className={styles.listBox}>
                    {locationLoading && (
                        <Fragment key={'loading'}>
                            <div className={styles.optionsLabel}>Aktueller Standort</div>
                            <li className={styles.loading}>Wird geladen...</li>
                        </Fragment>
                    )}
                    {groupedOptions.map((g, index) => {
                        const group = g as AutocompleteGroupedOption<Place>;
                        return (
                            <Fragment key={group.key}>
                                <div className={styles.optionsLabel}>{group.group}</div>
                                {group.options.map((option) => {
                                    option = option as Place;
                                    if (option.notFound) {
                                        return (
                                            <li className={styles.noOption} key={'notFound'}>
                                                Wir konnte leider keine Ergebnisse finden, bitte überprüfe deine
                                                Eingabe.
                                                {showHelp && (
                                                    <>
                                                        <br />
                                                        <br />
                                                        Du kannst deine Adresse nicht finden? Achte darauf, dass die
                                                        Straße richtig geschrieben ist. Versuche die Adresse in
                                                        folgender Reihenfolge einzugeben: Straße Hausnummer,
                                                        Postleitzahl, Ort.
                                                    </>
                                                )}
                                            </li>
                                        );
                                    }

                                    return (
                                        <li
                                            {...getOptionProps({ option, index })}
                                            className={styles.option}
                                            key={`${option.place_id}-${option.isCurrentLocation}`}
                                        >
                                            <MdOutlinePlace /> {option.customDisplayName}
                                        </li>
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                </ul>
            </>
        </div>
    );
};
