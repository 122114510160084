import React, { createRef, Dispatch, useEffect, useMemo, useState } from 'react';

import { calculateIsOpen, isPreOrderCurrentlyPossible } from '@bestelleck/shared';
import { RestaurantDetail, Extra, Restaurant, MenuGroup, OrderType } from '@bestelleck/utils';
import { Card, CardContent, useMediaQuery, Backdrop } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { Helmet } from 'react-helmet';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';

import { setSelectedRestaurant } from '../../redux/app/app.actions';
import { checkOrderType, setOrderType } from '../../redux/orderType/orderType.actions';
import { updateRestaurant } from '../../redux/restaurant/restaurant.actions';
import { RootState } from '../../redux/store';
import { emptyRestaurantDetail } from '../../types/Restaurant';
import { baseUrl, companyName } from '../../util/constants';
import { useHorizontalScroll } from '../../util/scroll/horizontalScroll';
import { useTrackView } from '../../util/tracking/trackPage';
import { customFetch, getUniqueKey, isGroupAvailable } from '../../util/utils';

import CartComponent from './Cart/Cart';
import MenuCouponComponent from './MenuCoupon/MenuCoupon';
import MenuGroupComponent from './MenuGroup/MenuGroup';
import styles from './Restaurant.module.scss';
import RestaurantHeaderComponent from './RestaurantHeader/RestaurantHeader';
import { RestaurantSkeletonComponent } from './RestaurantSkeleton/RestaurantSkeleton';
import SelectOrderDialog from './SelectOrderDialog/SelectOrderDialog';
interface RestaurantProps {
    restaurantMetadata?: Restaurant;
}

const RestaurantComponent: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch: Dispatch<any> = useDispatch();
    const scrollRef = useHorizontalScroll();

    useTrackView(`Restaurant_${id}`);

    const smallScreen = useMediaQuery('(max-width:750px)');
    const isMediumScreen = useMediaQuery('(max-width:1001px)');

    const history = useHistory();
    const location = useLocation<RestaurantProps>();
    const searchFilter = useSelector((state: RootState) => state.app.search, shallowEqual);
    const { place } = useSelector((state: RootState) => state.orderType, shallowEqual);

    const [backdrop, setBackdrop] = useState(false);

    const { orderType, needToCheck, showDialog } = useSelector((state: RootState) => state.orderType, shallowEqual);
    const route = useSelector((state: RootState) => state.app.route, shallowEqual);
    const isDelivery = orderType === OrderType.Delivery;
    const restaurantState = useSelector((state: RootState) => state.restaurant, shallowEqual);

    let restaurantMetadata = location.state?.restaurantMetadata;

    if (!restaurantMetadata && restaurantState.restaurants && restaurantState.restaurants.length > 0) {
        const foundRestaurant = restaurantState.restaurants.find((restaurant) => restaurant.id === id);
        if (foundRestaurant) {
            restaurantMetadata = foundRestaurant;
        }
    }

    const metaDataWithoutMenu = restaurantMetadata as Omit<Restaurant, 'menu'>;

    const restaurantWithoutMenu: RestaurantDetail = {
        ...emptyRestaurantDetail,
        ...metaDataWithoutMenu,
    };

    const [restaurant, setRestaurant] = useState<RestaurantDetail>(restaurantWithoutMenu);

    const [isLoaded, setIsLoaded] = useState(false);
    const [menuGroups, setMenuGroups] = useState<MenuGroup[]>([]);
    const [menu, setMenu] = useState<{ name: string; visible: boolean; index: number }[]>([]);
    const [groupIndex, setGroupIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    const [elRefs, setElRefs] = React.useState<{ menuRefs: any[]; elementRefs: any[] }>({
        menuRefs: [],
        elementRefs: [],
    });
    const menuRef = React.useRef<HTMLDivElement>(null);

    const deliveryAvailable = restaurant.orderTypes.find((value) => value === OrderType.Delivery.toString())
        ? true
        : false;

    const [showSelectDialog, setShowSelectDialog] = useState(false);

    const scrollToGroup = (index: number) => {
        setIsScrolling(true);
        elRefs.elementRefs[index].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        setTimeout(() => setIsScrolling(false), 1000);
    };

    useEffect(() => {
        setShowSelectDialog(needToCheck && deliveryAvailable && showDialog);
    }, [needToCheck, deliveryAvailable, showDialog]);

    useEffect(() => {
        if (!deliveryAvailable && orderType === OrderType.Delivery) {
            dispatch(setOrderType({ orderType: OrderType.PickUp }));
        }
        dispatch(checkOrderType({ previousRoute: route.previous, deliveryAvailable, showDialog: false }));
    }, [deliveryAvailable, orderType, dispatch, route]);

    useEffect(() => {
        let previous = 0;
        const menuWidths = elRefs.menuRefs.map((r, index) => {
            if (r.current) {
                const boundingBox = r.current.getBoundingClientRect();
                const current = previous;
                previous = boundingBox.right - boundingBox.left + current + 10;
                if (index === 0) return 0;
                return current;
            }
            return 0;
        });
        const handleScroll = () => {
            for (const i in elRefs.elementRefs) {
                const index = Number(i);
                const ref = elRefs.elementRefs[index];
                const isIn = isInViewport(ref);
                if (isIn && index !== groupIndex) {
                    setGroupIndex(index);
                    if (isMediumScreen && menuRef.current && !isScrolling) {
                        menuRef.current.scrollTo({
                            left: menuWidths[index],
                            behavior: 'smooth',
                        });
                    }
                    break;
                }
            }
        };
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [elRefs, groupIndex, menuRef, isScrolling, isMediumScreen]);

    const isInViewport = (ref: any, offset = 0) => {
        if (!ref.current) return false;
        const top = ref.current.getBoundingClientRect().top;
        return top + offset >= 0 && top + window.innerHeight / 2 <= window.innerHeight;
    };

    let url = `${baseUrl}/restaurants/${id}?useNewScheduleDays=true&useSelectionGroups=true&orderType=${orderType}`;

    if (place.lat !== '' && place.lon !== '') {
        url = `${url}&latitude=${place.lat}&longitude=${place.lon}`;
    }

    useEffect(() => {
        const loadRestaurant = async () => {
            try {
                // Edge Case when user selects delivery in entry and then manually navigates to restaurant
                if (orderType === OrderType.Delivery && (place.lat === '' || place.lon === '')) {
                    dispatch(setOrderType({ orderType: OrderType.PickUp }));
                    return;
                }
                const response = await customFetch<RestaurantDetail>(url);
                setIsLoaded(true);
                setRestaurant(response);
                dispatch(setSelectedRestaurant({ restaurant: response }));
                if (isDelivery && !response.delivery?.place) {
                    dispatch(setOrderType({ orderType: OrderType.PickUp }));
                }
                const storedIndex = restaurantState.restaurants?.findIndex((entry) => entry.id === response.id);
                const updatedRestaurantState = [...restaurantState.restaurants];
                if (storedIndex >= 0) {
                    updatedRestaurantState[storedIndex] = response;
                } else {
                    updatedRestaurantState.push(response);
                }

                dispatch(updateRestaurant(updatedRestaurantState));

                const sortedGroups = response.menu.groups.sort((a, b) => {
                    const aIsAvailable = isGroupAvailable(a);
                    const bIsAvailable = isGroupAvailable(b);
                    return aIsAvailable === bIsAvailable ? 0 : aIsAvailable ? -1 : 1;
                });
                setMenuGroups(sortedGroups);
                setMenu(sortedGroups.map((group, index) => ({ name: group.name, visible: true, index })));
            } catch (error: any) {
                history.push('/discover');
            }
        };
        loadRestaurant();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, history]);

    useEffect(() => {
        setElRefs((elRefs) => {
            const elementRefs = Array(restaurant?.menu?.groups?.length)
                .fill(null)
                .map((_, i) => elRefs.elementRefs[i] || createRef());
            const menuRefs = Array(restaurant?.menu?.groups?.length)
                .fill(null)
                .map((_, i) => elRefs.menuRefs[i] || createRef());
            return { elementRefs, menuRefs };
        });
    }, [restaurant?.menu?.groups?.length]);

    const isOpen = calculateIsOpen(restaurant, orderType);
    const extras: Extra[] = restaurant.menu.extras;
    const preOrderPossible = isPreOrderCurrentlyPossible(restaurant, isDelivery, isOpen);
    const groups = useMemo(() => {
        const templates = restaurant?.menu.templates ? restaurant.menu.templates : [];
        return menuGroups.map((group, index) => {
            if (isLoaded) {
                const isVisible = (visible: boolean) => {
                    const newMenu = [...menu];
                    if (newMenu[index]) {
                        newMenu[index].visible = visible;
                        setMenu(newMenu);
                    }
                };
                return (
                    <div key={getUniqueKey()} ref={elRefs.elementRefs[index]}>
                        <ErrorBoundary>
                            <MenuGroupComponent
                                extras={extras}
                                group={group}
                                templates={templates}
                                searchFilter={searchFilter}
                                isOpen={isOpen || preOrderPossible}
                                updateMenuDisplay={isVisible}
                                showItemIdentifier={restaurant.menu.showMenuItemIdentifiers}
                                deliveryAvailable={deliveryAvailable}
                                selectionGroups={restaurant.menu.selectionGroups}
                            ></MenuGroupComponent>
                        </ErrorBoundary>
                    </div>
                );
            } else {
                return <div key={index}>Loading ...</div>;
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuGroups, extras, searchFilter, elRefs, isOpen]);

    const menuDisplay = menu
        .filter((group) => group.visible)
        .map((group, index) => (
            <div
                ref={elRefs.menuRefs[index]}
                key={getUniqueKey()}
                className={group.index === groupIndex ? styles.menuEntrySelected : styles.menuEntry}
                onClick={() => scrollToGroup(group.index)}
            >
                {group.name}
            </div>
        ));

    const menuCoupon = isDelivery ? restaurant?.menu.coupon?.delivery : restaurant?.menu.coupon?.pickup;

    if (restaurant && restaurant.id) {
        const minimumOrderValue = isDelivery ? restaurant.delivery?.places[0]?.minimumOrderValue : 0;

        const groupComponent = () => {
            if (isLoaded) {
                return (
                    <div>
                        {menuCoupon && <MenuCouponComponent coupon={menuCoupon}></MenuCouponComponent>}
                        {groups}
                    </div>
                );
            } else {
                return <div>Loading ...</div>;
            }
        };

        return (
            <div className={styles.Restaurant}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>
                        {restaurant.name} {restaurant.contact.city} | {companyName}
                    </title>
                    <meta
                        name="description"
                        content={`Bestelle online von ${restaurant.name} ${restaurant.contact.city} jetzt über ${companyName}. Genieße volle Flexibilität und nutze verschiedene Zahlungsmethoden.`}
                    ></meta>
                </Helmet>
                <div className={styles.info}>
                    <RestaurantHeaderComponent restaurant={restaurant}></RestaurantHeaderComponent>
                </div>
                <div className={styles.container}>
                    <div className={styles.grid}>
                        <div className={styles.menu}>
                            <div className={styles.menuContainer} ref={menuRef}>
                                {!isMediumScreen && (
                                    <Card className={styles.menuCard}>
                                        <CardContent> {menuDisplay}</CardContent>
                                    </Card>
                                )}
                                {isMediumScreen && (
                                    <div ref={scrollRef} className={styles.menuCard}>
                                        {menuDisplay}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backdrop}></Backdrop>
                        <div className={styles.main}>{groupComponent()}</div>
                        <div className={styles.checkout}>
                            <CartComponent
                                restaurant={restaurant}
                                showButton={true}
                                collapsible={smallScreen}
                                minimumOrderValue={minimumOrderValue}
                                setBackdrop={setBackdrop}
                            ></CartComponent>
                        </div>
                    </div>
                </div>

                {showSelectDialog && (
                    <SelectOrderDialog
                        restaurant={restaurant}
                        handleClose={() => {
                            setShowSelectDialog(false);
                        }}
                        open={showSelectDialog}
                    ></SelectOrderDialog>
                )}
            </div>
        );
    } else {
        return <RestaurantSkeletonComponent />;
    }
};

export default RestaurantComponent;
