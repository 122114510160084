import React, { useCallback, useEffect } from 'react';

import { Coupon as CouponType } from '@bestelleck/utils';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Clear } from '@mui/icons-material';
import { Button, TextField, IconButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { baseUrl } from '../../../../util/constants';
import { usePrevious } from '../../../../util/usePrevious';
import { customFetch } from '../../../../util/utils';
import { FormResult } from '../../Checkout';

import styles from './Coupon.module.scss';

type CouponProps = {
    paymentMethod: string;
    setMessage: ({ isError, message }: { isError: boolean; message: string }) => void;
    setCoupon: (coupon: CouponType | undefined) => void;
    coupon: CouponType | undefined;
};

export const CouponComponent: React.FC<CouponProps> = ({ setMessage, setCoupon, paymentMethod, coupon }) => {
    const { register, watch } = useFormContext<FormResult>();
    const { trackEvent } = useMatomo();
    const { id } = useParams<{ id: string }>();
    const prevPaymentMethod = usePrevious(paymentMethod);

    const fields = watch();

    const trackClick = () => {
        trackEvent({ action: `Click coupon`, category: 'Checkout' });
    };

    const checkCoupon = useCallback(
        (coupon: string) => {
            customFetch<CouponType>(`${baseUrl}/coupons/${id}/${coupon}`)
                .then((response: CouponType) => {
                    if (response.paymentOptions.find((paymentOption) => paymentOption === paymentMethod)) {
                        setCoupon(response);
                        setMessage({ isError: false, message: 'Gutschein erfolgreich angewendet' });
                    } else {
                        setCoupon(undefined);
                        setMessage({
                            isError: true,
                            message: 'Der Gutschein kann nicht für die gewählte Zahlungsart verwendet werden',
                        });
                    }
                })
                .catch((error) => {
                    setMessage({ isError: true, message: error.message });
                });
        },
        [id, paymentMethod, setCoupon, setMessage],
    );

    useEffect(() => {
        if (coupon?.key && paymentMethod && paymentMethod !== prevPaymentMethod) {
            checkCoupon(coupon.key);
        }
    }, [paymentMethod, coupon?.key, checkCoupon, prevPaymentMethod]);

    return (
        <>
            <h3> Hast du einen Gutschein?</h3>
            <div className={styles.row}>
                <TextField
                    {...register('coupon')}
                    onClick={() => {
                        trackClick();
                    }}
                    onFocus={() => {
                        trackClick();
                    }}
                    id="coupon"
                    name="coupon"
                    label="Gutschein eingeben"
                    variant="outlined"
                    disabled={coupon !== undefined}
                    className={styles.half}
                    color="secondary"
                    sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#000000',
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                sx={{ visibility: coupon ? 'visible' : 'hidden' }}
                                onClick={() => {
                                    setCoupon(undefined);
                                }}
                            >
                                <Clear />
                            </IconButton>
                        ),
                    }}
                />
                {fields.coupon && !coupon && (
                    <Button
                        variant="contained"
                        color="secondary"
                        className={styles.button}
                        onClick={() => {
                            checkCoupon(fields.coupon);
                        }}
                    >
                        Gutschein anwenden
                    </Button>
                )}
            </div>
        </>
    );
};
